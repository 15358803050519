<template>
  <div>
    <b-modal
      v-model="onControl"
      :header-bg-variant="alertMerchantWithoutCardDiscovery ? 'warning':'primary'"
      size="default"
      centered
      no-close-on-backdrop
      @hidden="closeModal"
    >
      <template #modal-title>
        <strong class="text-uppercase">Cards of merchant '{{ merchant.description }}'</strong>
      </template>
      <b-container class="py-2">
        <validation-observer ref="formTypeCards">
          <b-row>
            <b-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                name="cards"
                rules="required"
              >
                <b-form-group
                  label="Cards Merchant"
                  label-for="cards"
                >
                  <v-select
                    id="cards"
                    v-model="selectedTypeCards"
                    :options="optionsTypesCards"
                    :reduce="(option) => option.id"
                    label="name"
                    placeholder="-- Select cards --"
                    multiple
                    :class="errors[0] ? 'rounded border-danger ' : ''"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <strong
            v-if="alertMerchantWithoutCardDiscovery"
            class="mt-1 text-warning text-center w-100 d-flex"
            style="letter-spacing: 0.5px;"
          >* This merchant is the only one that has the discovery card associated with it.</strong>
        </validation-observer>
      </b-container>
      <template #modal-footer>
        <b-row>
          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              variant="secondary"
              @click="closeModal"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              class="ml-1"
              @click="saveTypeCards"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-1"
              />
              Save
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>
<script>
import ServicesMerchants from '@/views/management/views/settings/views/merchant/services/merchant.services';
import { mapGetters } from 'vuex';

export default {
  props: {
    merchant: {
      required: true,
      default: () => { },
      type: Object,
    },
  },
  data() {
    return {
      onControl: false,
      optionsTypesCards: [],
      selectedTypeCards: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    currentTypesCards() {
      return JSON.parse(this.merchant.currentTypeCards).map(item => item.id);
    },
    alertMerchantWithoutCardDiscovery() {
      // id 4: card discovery
      /* --- check if there is only 1 merchant who has the discovery card.
      If you have the discovery card on your current cards and you are removing it--*/
      return this.merchant.merchantWithCardDiscovery === 1 && (this.currentTypesCards.includes(4) && !this.selectedTypeCards.includes(4));
    },
  },
  async mounted() {
    await this.getTypesOfCards();
    this.selectedTypeCards = JSON.parse(this.merchant.currentTypeCards).map(item => item.id);
    this.onControl = true;
  },
  methods: {
    closeModal() {
      this.onControl = false;
      this.$emit('closeModal');
    },
    async getTypesOfCards() {
      try {
        const { data } = await ServicesMerchants.getTypesCards();
        this.optionsTypesCards = data;
      } catch (error) {
        this.showErrorSwal();
        console.log('error getTypeCards', error);
      }
    },
    async saveTypeCards() {
      try {
        const isValidForm = await this.$refs.formTypeCards.validate();
        if (!isValidForm) {
          return;
        }
        /* -----------VALID 'new values' different to 'old values'--------- */
        if (JSON.stringify(this.currentTypesCards.sort()) === JSON.stringify(this.selectedTypeCards.sort())) {
          this.showInfoSwal('The \'new values\' must be different from \'old values\'');
          return;
        }
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) {
          return;
        }
        const params = {
          merchant_id: this.merchant.id,
          user_id: this.currentUser.user_id,
          type_cards: this.selectedTypeCards,
        };
        const { status } = await ServicesMerchants.insertMerchantCards(params);
        if (status === 200) {
          this.onControl = false;
          this.$emit('successAction');
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
        console.log('error getTypeCards', error);
      }
    },
  },
};
</script>

<style>

[dir] .dark-layout .v-select:not(.vs--single) .vs__selected{
  background-color: blue !important;
}

[dir="ltr"] .v-select:not(.vs--single) .vs__selected {
  background-image: linear-gradient(to right, #000046, #1CB5E0);  /* width:100px;
  height: 50px; */
  justify-content: end;
  font-weight: bold;
  /* transform: rotate(-90deg); */
}

</style>
