<template>
  <div>
    <b-modal
      v-model="onControl"
      size="lg"
      scrollable
      hide-footer
      @hidden="closeModal"
    >
      <template #modal-title>
        <div>
          <feather-icon
            icon="AlignLeftIcon"
            size="18"
            class="text-white mr-1"
          />
          <strong class="text-uppercase">Tracking of programs for  '{{ merchant.descriptionMerchant }}''</strong>
        </div>
      </template>
      <b-card class="shadow-none">
        <filter-slot
          :no-visible-principal-filter="true"
          :filter-principal="filterPrincipal"
          :filter="[]"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          @reload="refreshTable()"
        >
          <template #table>
            <b-table
              ref="refTableTracking"
              primary-key="id"
              hover
              small
              show-empty
              :items="providerTracking"
              :fields="fields"
              :current-page="paginate.currentPage"
              :per-page="paginate.perPage"
              :busy.sync="isBusy"
              :filter="searchInput"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>
              <template #cell(programs)="data">
                <div style="max-width: 350px">
                  <b-badge
                    v-for="(item , index) in JSON.parse(data.item.programs)"
                    :key="index"
                    class="rounded-sm"
                    style="margin: 3px;padding: 8px"
                  >
                    {{ item }}
                  </b-badge>
                </div>
              </template>
              <template #cell(created_by)="data">
                <span :class="data.index=== 0 ? 'font-weight-bolder':''">{{ data.item.created_by }}</span>
              </template>
              <template #cell(created_at)="data">
                <span :class="data.index=== 0 ? 'font-weight-bolder':''">{{ data.item.created_at | myGlobalDay }}</span>
              </template>
            </b-table>
          </template>
        </filter-slot>
      </b-card>
    </b-modal>
  </div>
</template>
<script>
import ServicesMerchants from '@/views/management/views/settings/views/merchant/services/merchant.services';
import fields from '@/views/management/views/settings/views/merchant/data/field.trackingPrograms';

export default {
  props: {
    merchant: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      onControl: false,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search by Merchant name...',
        model: '',
      },
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 100,
      },
      startPage: 0,
      toPage: 0,
      isBusy: false,
      searchInput: '',
      fields,
    };
  },
  mounted() {
    this.onControl = true;
  },
  methods: {
    closeModal() {
      this.onControl = false;
      this.$emit('closeModal');
    },
    async providerTracking() {
      try {
        this.isBusy = true;
        const params = {
          page: this.paginate.currentPage,
          perPage: parseInt(this.paginate.perPage, 10),
          merchant_id: this.merchant.merchantId,
        };
        const { data } = await ServicesMerchants.getTrackingProgramsMerchant(params);
        this.totalRows = data.total || 0;
        this.startPage = data.from || 0;
        this.toPage = data.to || 0;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.isBusy = false;
        return data.data;
      } catch (error) {
        this.showErrorSwal();
        this.isBusy = false;
        console.log('error providerTracking', error);
      }
      return [];
    },
    refreshTable() {
      this.$refs.refTableTracking.refresh();
    },
  },
};
</script>
