<template>
  <div>
    <b-modal
      v-model="onControl"
      size="lg"
      scrollable
      hide-footer
      @hidden="closeModal"
    >
      <template #modal-title>
        <div>
          <feather-icon
            icon="AlignLeftIcon"
            size="18"
            class="text-white mr-1"
          />
          <strong class="text-uppercase">Tracking of credentials for  '{{ merchant.descriptionMerchant }}''</strong>
        </div>
      </template>
      <b-card class="shadow-none">
        <filter-slot
          :no-visible-principal-filter="true"
          :filter-principal="filterPrincipal"
          :filter="[]"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          @reload="refreshTable()"
        >
          <template #table>
            <b-table
              ref="refTableTracking"
              primary-key="id"
              hover
              small
              show-empty
              :items="providerTracking"
              :fields="fields"
              :current-page="paginate.currentPage"
              :per-page="paginate.perPage"
              :busy.sync="isBusy"
              :filter="searchInput"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle mr-1" />
                  <strong>Loading ...</strong>
                </div>
              </template>
              <template #cell(login_id)="data">
                <strong
                  v-if="data.index === 0"
                >
                  {{ data.item.login_id }}
                </strong>
                <span v-else>
                  {{ data.item.login_id }}
                </span>
              </template>
              <template #cell(key)="data">
                <span :class="data.index ===0 ? 'font-weight-bolder':''">
                  <template v-if="currentUser.user_id===1">
                    {{ data.item.key }}
                  </template>
                  <template v-else>
                    {{ data.item.key | hiddenString }}
                  </template>
                </span>
              </template>
              <template #cell(created_by)="data">
                <strong v-if="data.index===0">{{ data.item.created_by_name }}</strong>
                <span v-else>{{ data.item.created_by_name }}</span>
              </template>
              <template #cell(created_at)="data">
                <strong v-if="data.index===0">{{ data.item.created_at | myGlobalDay }}</strong>
                <span v-else>{{ data.item.created_at | myGlobalDay }}</span>
              </template>
            </b-table>
          </template>
        </filter-slot>
      </b-card>
    </b-modal>
  </div>
</template>
<script>
import ServicesMerchants from '@/views/management/views/settings/views/merchant/services/merchant.services';
import fields from '@/views/management/views/settings/views/merchant/data/field.trackingCredentials';
import { mapGetters } from 'vuex';

export default {
  filters: {
    hiddenString(value) {
      return value.replace(/./g, '*');
    },
  },
  props: {
    merchant: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      onControl: false,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search by Merchant name...',
        model: '',
      },
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 100,
      },
      startPage: 0,
      toPage: 0,
      isBusy: false,
      searchInput: '',
      fields,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  mounted() {
    this.onControl = true;
    if (this.merchant.field.toLowerCase() === 'login_id') {
      this.fields[0].tdClass = 'list-group-item-success';
      this.fields[1].tdClass = '';
    } else {
      this.fields[0].tdClass = '';
      this.fields[1].tdClass = 'list-group-item-success';
    }
  },
  methods: {
    closeModal() {
      this.onControl = false;
      this.$emit('closeModal');
    },
    async providerTracking() {
      try {
        this.isBusy = true;
        const params = {
          page: this.paginate.currentPage,
          perPage: parseInt(this.paginate.perPage, 10),
          merchant_id: this.merchant.merchantId,
        };
        const { data } = await ServicesMerchants.getTrackingMerchantCredentials(params);
        this.totalRows = data.total || 0;
        this.startPage = data.from || 0;
        this.toPage = data.to || 0;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;
        this.isBusy = false;
        return data.data;
      } catch (error) {
        this.showErrorSwal();
        this.isBusy = false;
        console.log('error providerTracking', error);
      }
      return [];
    },
    refreshTable() {
      this.$refs.refTableTracking.refresh();
    },
  },
};
</script>
