<template>
  <div>
    <b-modal
      v-model="onControl"
      size="md"
      centered
      no-close-on-backdrop
      @hidden="closeModal"
    >
      <template #modal-title>
        <strong class="text-uppercase">
          <feather-icon
            icon="PlusIcon"
            class="text-white mr-1"
            size="18"
          />
          Create new Merchant</strong>
      </template>
      <b-card class="shadow-none mb-0 pb-0">
        <validation-observer ref="formNewMerchant">
          <b-row>
            <b-col cols="12">
              <validation-provider
                v-slot="{errors}"
                name="description"
                rules="required"
              >
                <b-form-group
                  label="Description"
                  label-for="description"
                >
                  <b-form-input
                    v-model="description"
                    placeholder="Description"
                    :state="errors[0]?false:null"
                  />
                  <small
                    v-if="errors[0]"
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              xl="6"
            >
              <validation-provider
                v-slot="{errors}"
                name="login_id"
                rules="required|max:15|min: 3"
              >
                <b-form-group
                  label="Login Id"
                  label-for="login_id"
                >
                  <b-form-input
                    v-model="login_id"
                    placeholder="Login Id"
                    :state="errors[0]?false:null"
                  />
                  <small
                    v-if="errors[0]"
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              xl="6"
            >
              <validation-provider
                v-slot="{errors}"
                name="key"
                rules="required|max:30|min: 3"
              >
                <b-form-group
                  label="Key"
                  label-for="key"
                >
                  <b-input-group>
                    <b-form-input
                      v-model="key"
                      auto-complete="off"
                      placeholder="Write your key here"
                      :state="errors[0] ? false:null"
                      :type="showPassword?'text':'password'"
                    />
                    <b-input-group-append>
                      <b-button
                        variant="light"
                        :class="!isDarkSkin ? 'border-dark':''"
                        @click="showPassword=!showPassword"
                      >
                        <feather-icon
                          :icon="!showPassword ? 'EyeIcon':'EyeOffIcon'"
                          size="13"
                        />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <small
                    v-if="errors[0]"
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              xl="6"
            >
              <validation-provider
                v-slot="{errors}"
                name="penalty"
                rules="required|validate-amount|max_value:999999.99"
              >
                <b-form-group
                  label="Penalty"
                  label-for="penalty"
                >
                  <money
                    v-model="penalty"
                    v-bind="{
                      decimal: '.',
                      thousands: ',',
                      prefix: '$ ',
                      precision: 2,
                      masked: false,
                    }"
                    class="form-control text-start rounded"
                  />
                  <small
                    v-if="errors[0]"
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              xl="12"
            >
              <validation-provider
                v-slot="{errors}"
                name="Cards"
                rules="required"
              >
                <b-form-group
                  label="Cards"
                  label-for="cards"
                >
                  <v-select
                    id="cards"
                    v-model="selectedTypeCards"
                    :options="optionsTypesCards"
                    :reduce="(option) => option.id"
                    label="name"
                    placeholder="-- Select cards --"
                    multiple
                    class="rounded"
                    :class="errors[0]?'border-danger':''"
                    :style="!isDarkSkin ? `border: 1px solid  ${errors[0]?'red':'#99A3A4'} !important;`:`border: 1px solid ${errors[0]?'red':'white'} !important;`"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              xl="12"
            >
              <validation-provider
                v-slot="{errors}"
                name="Services"
                rules="required"
              >
                <b-form-group
                  label="Services"
                  label-for="services"
                >
                  <v-select
                    id="services"
                    v-model="selectedServices"
                    :options="optionsServices"
                    :reduce="(option) => option.id"
                    label="value"
                    placeholder="-- Select services --"
                    multiple
                    class="rounded"
                    :class="errors[0]?'border border-danger':''"
                    :style="!isDarkSkin ? `border: 1px solid  ${errors[0]?'red':'#99A3A4'} !important;`:`border: 1px solid ${errors[0]?'red':'white'} !important;`"
                    @input="updateSelectedServicesList"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row
            v-if="listServicesToAdd.length"
            class="mt-1 p-1 rounded border-light"
            :class="!isDarkSkin ? 'list-group-item-light':''"
          >
            <b-col cols="12">
              <div class="d-block d-xl-flex justify-content-between align-items-center">
                <strong class="d-flex w-100 w-xl-auto">Services to add</strong>
                <span class="text-warning p-0">These services will be deactivated if found in other merchants.</span>
              </div>
              <b-list-group class="mt-1">
                <template>
                  <b-list-group-item
                    v-for="(item,index) in listServicesToAdd"
                    :key="index"
                    class="text-uppercase d-flex justify-content-between align-items-center"
                  >
                    <span>{{ item.value }}</span>
                    <div class="d-flex flex-column">
                      <small>Day to be modified in Authorize.net:</small>
                      <kendo-datepicker
                        v-model="item.date"
                        class="w-100"
                        :format="'yyyy-MM-dd'"
                        :parse-formats="['yyyy-MM-dd']"
                        :min="new Date()"
                        :max="new Date(2100, 0, 1)"
                        :class="isValidHighlightedDate(item.date)?'border-danger rounded':''"
                      />
                      <small
                        v-if="isValidHighlightedDate(item.date)"
                        class="text-danger"
                        style="margin-top:5px"
                      >
                        This date is not valid for this operation.
                      </small>
                    </div>
                  </b-list-group-item>
                </template>
              </b-list-group>
            </b-col>
          </b-row>
        </validation-observer>
      </b-card>
      <template #modal-footer>
        <b-row>
          <b-col cols="12">
            <b-button
              variant="secondary"
              @click="closeModal"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              class="ml-1"
              @click="saveMerchant"
            >
              <feather-icon
                icon="SaveIcon"
                size="15"
                class="mr-1 text-white"
              />
              Save
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>
<script>
import ServicesMerchants from '@/views/management/views/settings/views/merchant/services/merchant.services';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      onControl: false,
      description: '',
      login_id: '',
      key: '',
      penalty: 0.00,
      selectedServices: [],
      optionsServices: [],
      selectedTypeCards: [],
      optionsTypesCards: [],
      showPassword: false,
      listServicesToAdd: [],
      validation_date: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  async mounted() {
    await this.getTypesOfCards();
    await this.getPrograms();
    this.onControl = true;
  },
  methods: {
    closeModal() {
      this.onControl = false;
      this.$emit('closeModal');
    },
    async getTypesOfCards() {
      try {
        const { data } = await ServicesMerchants.getTypesCards();
        this.optionsTypesCards = data;
      } catch (error) {
        this.showErrorSwal();
        console.log('error getTypeCards', error);
      }
    },
    async getPrograms() {
      try {
        const { data } = await ServicesMerchants.getAllPrograms();
        this.optionsServices = data;
      } catch (error) {
        console.log('error get programs', error);
        this.showErrorSwal();
      }
    },
    async saveMerchant() {
      try {
        const isValid = await this.$refs.formNewMerchant.validate();
        if (!isValid || this.validation_date) {
          return;
        }
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) {
          return;
        }
        this.addPreloader();
        const params = {
          description: this.description,
          login_id: this.login_id,
          key: this.key,
          penalty: this.penalty,
          cards: this.selectedTypeCards,
          services: this.listServicesToAdd,
          user_id: this.currentUser.user_id,
        };
        const { data } = await ServicesMerchants.insertMerchant(params);
        if (data.status === 0) {
          this.showWarningSwal(data.message);
          this.removePreloader();
          return;
        }
        this.removePreloader();
        if (data.data.result === -1) {
          this.showInfoSwal('There is already a merchant with this name', 'MERCHANT');
        } else if (data.data.result === 1) {
          this.onControl = false;
          this.showSuccessSwal();
          this.$emit('successAction');
        } else {
          this.showInfoSwal('An unexpected inconvenience occurred. Please try again', 'MERCHANT');
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
      }
    },
    // Función auxiliar para verificar si un año es bisiesto
    isLeapYear(year) {
      return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
    },
    updateSelectedServicesList() {
      this.listServicesToAdd = this.selectedServices.map(id => {
        const serviceFound = this.optionsServices.find(option => option.id === id);

        // default date to change is 'next day'
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);

        // Validación para ajustar la fecha por defecto si es 5, 10, 15, 20, 25, o 30, o si es 28 o 29 de febrero en años bisiestos
        const dayOfMonth = tomorrow.getDate();
        const month = tomorrow.getMonth();
        const year = tomorrow.getFullYear();
        if ([5, 10, 15, 20, 25, 30].includes(dayOfMonth) || (dayOfMonth === 28 && month === 1 && this.isLeapYear(year))) {
          // these days are not allowed to be changed so 'an extra day' is added
          tomorrow.setDate(dayOfMonth + 1);
        }
        return {
          id: serviceFound.id,
          value: serviceFound.value,
          status: 0,
          date: tomorrow.toISOString().slice(0, 10), // Formateamos la fecha como 'AAAA-MM-DD'
        };
      });
    },
    isValidHighlightedDate(dateString) {
      if (!dateString) return false;
      const selectedDate = new Date(`${dateString}T00:00:00`);
      const dayValue = selectedDate.getDate();
      const monthValue = selectedDate.getMonth();
      const yearValue = selectedDate.getFullYear();
      const isLeapYear = (yearValue % 4 === 0 && yearValue % 100 !== 0) || yearValue % 400 === 0;

      if (dayValue === 5 || dayValue === 10 || dayValue === 15 || dayValue === 20 || dayValue === 25 || dayValue === 30) {
        this.validation_date = true;
        return true;
      }
      if (monthValue === 1 && (isLeapYear ? (dayValue === 29) : (dayValue === 28))) {
        this.validation_date = true;
        return true;
      }
      if (dayValue === 28 && [0, 2, 4, 6, 7, 9, 11].includes(monthValue)) {
        this.validation_date = true;
        return true;
      }
      if (dayValue === 30 && [3, 5, 8, 10].includes(monthValue)) {
        this.validation_date = true;
        return true;
      }
      this.validation_date = false;
      return false;
    },
  },
};
</script>
