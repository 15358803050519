export default [
  {
    key: 'login_id',
    label: 'Login Id',
  },
  {
    key: 'key',
    label: 'Key',
  },
  {
    key: 'created_by',
    label: 'Created By',
  },
  {
    key: 'created_at',
    label: 'Created At',
  },
];
