export default [
  {
    key: 'programs',
    label: 'Programs',
  },
  {
    key: 'created_by',
    label: 'Created By',
  },
  {
    key: 'created_at',
    label: 'Created at',
  },
];
