<template>
  <div>
    <b-modal
      v-model="onControl"
      size="md"
      centered
      no-close-on-backdrop
      @hidden="closeModal"
    >
      <template #modal-title>
        <strong class="text-uppercase">Services of merchant '{{ merchant.description }}'</strong>
      </template>
      <b-container class="p-1">
        <!-- ...Current services... -->
        <b-row>
          <b-col cols="12">
            <h5>Current services:</h5>
            <ul
              class="list-group"
            >
              <template v-if="selectedServicesList.length">
                <li
                  v-for="(service, index) in selectedServicesList"
                  :key="index"
                  class="list-group-item d-flex justify-content-between"
                >
                  <span>
                    {{ service.value }}
                  </span>
                  <span
                    v-if="service.status === 0"
                    class="text-warning"
                  >
                    {{ service.date | myGlobal }} - Pending Update
                  </span>
                </li>
              </template>
              <template v-else>
                <li
                  class="list-group-item pointer-events-none d-flex justify-content-center align-items-center border-0"
                  :class="!isDarkSkin?'list-group-item-light':'border-light'"
                >
                  No Services
                </li>
              </template>
            </ul>
          </b-col>
        </b-row>
        <!-- ...Current services... -->
      </b-container>
      <b-container class="p-1">
        <validation-observer ref="formServices">
          <b-row>
            <b-col cols="12">
              <validation-provider
                v-slot="{errors}"
                name="services"
                rules="required"
              >
                <b-form-group
                  label="Select Services"
                  label-for="services"
                >
                  <v-select
                    id="services"
                    v-model="selectedNewServices"
                    :options="optionsServices"
                    :reduce="(option) => option.id"
                    label="value"
                    placeholder="-- Select cards --"
                    multiple
                    :class="errors[0]?'rounded border-danger':''"
                    @input="updateSelectedServicesList"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </b-container>
      <b-container class="p-1">
        <!-- ...New services... -->
        <b-row
          v-if="selectedNewServices.length > 0"
        >
          <b-col cols="12">
            <div class="d-flex justify-content-between align-items-center mb-1">
              <h5 class="font-weight-bolder">
                New services:
              </h5>
              <strong
                class="text-warning jump"
                style="letter-spacing: 0.5px"
              >* These services will be deactivated if found in other merchants *</strong>
            </div>
            <ul class="list-group">
              <li
                v-for="(service, index) in selectedNewServices"
                :key="index"
                class="list-group-item d-flex justify-content-between"
              >
                <div>
                  <span>{{ service.value }}</span>
                </div>
                <div style="display:flex;flex-direction:column">
                  <label for="date-picker">Day to be modified in Authorize.net:</label>
                  <kendo-datepicker
                    v-model="service.date"
                    :format="'yyyy-MM-dd'"
                    :parse-formats="['yyyy-MM-dd']"
                    :min="new Date()"
                    :max="new Date(2100, 0, 1)"
                    :class="isValidHighlightedDate(service.date)?'highlighted-date':''"
                  />
                  <span
                    v-if="isValidHighlightedDate(service.date)"
                    class="text-danger"
                    style="margin-top:5px"
                  >
                    This date is not valid for this operation.
                  </span>
                </div>
              </li>
            </ul>
          </b-col>
        </b-row>
        <!-- ...New services... -->
      </b-container>
      <template #modal-footer>
        <b-row>
          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              variant="secondary"
              @click="closeModal"
            >
              Cancel
            </b-button>
            <b-button
              variant="primary"
              class="ml-1"
              @click="saveServices"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-1"
              />
              Save
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>
<script>
import ServicesMerchants from '@/views/management/views/settings/views/merchant/services/merchant.services';
import { mapGetters } from 'vuex';

export default {
  props: {
    merchant: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      onControl: false,
      selectedServices: [],
      optionsServices: [],
      selectedServicesList: [],
      selectedNewServices: [],
      serviceDate: null,
      validation_date: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  async mounted() {
    const currentServices = JSON.parse(this.merchant.currentServices);
    // Llenar this.selectedServices solo con los IDs de los servicios actuales
    this.selectedServices = currentServices.map(service => service.id);
    await this.getPrograms();
    // Agregar el parámetro 'status: "current"' a cada servicio en this.merchant.currentServices
    this.merchant.currentServices = currentServices.map(service => ({ ...service }));
    this.selectedServicesList = this.merchant.currentServices;
    this.onControl = true;
  },
  methods: {
    closeModal() {
      this.onControl = false;
      this.$emit('closeModal');
    },
    isValidHighlightedDate(dateString) {
      if (!dateString) return false;
      const selectedDate = new Date(`${dateString}T00:00:00`);
      const dayValue = selectedDate.getDate();
      const monthValue = selectedDate.getMonth();
      const yearValue = selectedDate.getFullYear();
      const isLeapYear = (yearValue % 4 === 0 && yearValue % 100 !== 0) || yearValue % 400 === 0;

      if (dayValue === 5 || dayValue === 10 || dayValue === 15 || dayValue === 20 || dayValue === 25 || dayValue === 30) {
        this.validation_date = true;
        return true;
      }
      if (monthValue === 1 && (isLeapYear ? (dayValue === 29) : (dayValue === 28))) {
        this.validation_date = true;
        return true;
      }
      if (dayValue === 28 && [0, 2, 4, 6, 7, 9, 11].includes(monthValue)) {
        this.validation_date = true;
        return true;
      }
      if (dayValue === 30 && [3, 5, 8, 10].includes(monthValue)) {
        this.validation_date = true;
        return true;
      }
      this.validation_date = false;
      return false;
    },
    // Función auxiliar para verificar si un año es bisiesto
    isLeapYear(year) {
      return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
    },
    // Método para deshabilitar fechas específicas (5, 10, 15, 20, 25, 30, y 28 o 29 de febrero en años bisiestos)
    updateSelectedServicesList() {
      // Filtramos los servicios seleccionados que no estaban en selectedServicesList
      const newServiceIds = this.selectedNewServices.filter(serviceId => !this.selectedServicesList.some(service => service.id === serviceId));

      // Creamos una nueva lista de servicios seleccionados con el estado 'pending' y fecha por defecto (día siguiente)
      this.selectedNewServices = newServiceIds.map(serviceId => {
        const serviceFound = this.optionsServices.find(option => option.id === serviceId);
        if (serviceFound) {
          const tomorrow = new Date();
          tomorrow.setDate(tomorrow.getDate() + 1);

          // Validación para ajustar la fecha por defecto si es 5, 10, 15, 20, 25, o 30, o si es 28 o 29 de febrero en años bisiestos
          const dayOfMonth = tomorrow.getDate();
          const month = tomorrow.getMonth();
          const year = tomorrow.getFullYear();
          if ([5, 10, 15, 20, 25, 30].includes(dayOfMonth) || (dayOfMonth === 28 && month === 1 && this.isLeapYear(year))) {
            tomorrow.setDate(dayOfMonth + 1);
          }

          return {
            id: serviceFound.id,
            value: serviceFound.value,
            status: 0,
            date: tomorrow.toISOString().slice(0, 10), // Formateamos la fecha como 'AAAA-MM-DD'
          };
        }
        return null;
      }).filter(Boolean);
    },
    async getPrograms() {
      try {
        const { data } = await ServicesMerchants.getAllPrograms();
        this.optionsServices = data.filter(item => !this.selectedServices.includes(item.id));
      } catch (error) {
        console.log('error get programs', error);
        this.showErrorSwal();
      }
    },
    async saveServices() {
      this.selectedServices = [...this.selectedServicesList, ...this.selectedNewServices];
      try {
        const isValidForm = await this.$refs.formServices.validate();
        if (!isValidForm || this.validation_date) {
          return;
        }
        const { isConfirmed } = await this.showConfirmSwal('Are you sure ?', 'These services will be deactivated if found in other merchants');
        if (!isConfirmed) {
          return;
        }
        const params = {
          merchant_id: this.merchant.id,
          user_id: this.currentUser.user_id,
          services: this.selectedServices,
        };
        const { status } = await ServicesMerchants.updateProgramsMerchant(params);

        if (status === 200) {
          this.showSuccessSwal();
          this.onControl = false;
          this.$emit('successAction');
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
        console.log('error saveServices', error);
      }
    },
  },
};
</script>
<style>
.highlighted-date {
  border: 1px solid red;
  border-radius:5px;
}
.jump{
  animation: jumper 2s ease-in-out infinite alternate;
}
@keyframes jumper{
  0%{
    transform: scale(1);
  }30%{
    transform: scale(1.03);
  }60%{
    transform: scale(1);
  }100%{
    transform: scale(1.03);
  }
}
</style>
