<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          cols="12"
          class="text-right"
        >
          <b-button
            id="btn-create-merchant"
            name="btn-create-merchant"
            variant="success"
            @click="showModalNewMerchant=true"
          >
            <feather-icon
              icon="PlusIcon"
              class="text-white"
            />
            CREATE
          </b-button>
        </b-col>
      </b-row>
      <filter-slot
        id="table-list-merchants"
        :filter-principal="filterPrincipal"
        :filter="[]"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reload="refreshTable()"
      >
        <template #table>
          <b-table
            ref="refTableMerchants"
            primary-key="id"
            hover
            show-empty
            :items="providerMerchants"
            :fields="fields"
            :current-page="paginate.currentPage"
            :per-page="paginate.perPage"
            :busy.sync="isBusy"
            :filter="searchInput"
            class="pr-4"
          >
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>
            <template #cell(login_id)="data">
              <validation-observer :ref="`fieldLoginId_${data.item.id}`">
                <validation-provider
                  v-slot="{errors}"
                  rules="required|max:15"
                >
                  <b-input-group style="width: 230px; padding: 10px">
                    <b-form-input
                      v-model="data.item.new_login_id"
                      placeholder="Write login Id"
                      :disabled="!data.item.edit_login_id"
                      class="text-dark"
                      :style="!data.item.edit_key ? (isDarkSkin ? 'background-color: #17202A !important;':'background-color: #EAEDED !important'):''"
                      :state="errors[0]?false:null"
                    />
                    <b-input-group-append>
                      <template v-if="data.item.edit_login_id === false">
                        <b-button
                          v-b-tooltip.hover.v-warning
                          variant="outline-warning"
                          title="Edit"
                          style="padding: 8px"
                          name="btn-edit-login"
                          @click="editField(data.item.id, 'edit_login_id')"
                        >
                          <feather-icon
                            icon="Edit2Icon"
                            size="13"
                            class="text-warning"
                          />
                        </b-button>
                        <b-button
                          v-b-tooltip.hover.v-primary
                          variant="outline-light"
                          title="Tracking"
                          style="padding: 8px;border-left: 1px solid orange !important;"
                          name="btn-tracking-login"
                          @click="showTrackingCredentials(data.item.id, data.item.description,'login_id')"
                        >
                          <feather-icon
                            icon="ListIcon"
                            size="13"
                            class="text-primary"
                          />
                        </b-button>
                      </template>
                      <template v-else>
                        <b-button
                          v-b-tooltip.hover.v-success
                          variant="success"
                          title="Save"
                          style="padding: 8px"
                          name="btn-update-credential"
                          @click="updateCredential(data.item.id)"
                        >
                          <feather-icon
                            icon="SaveIcon"
                            size="13"
                            class="text-white"
                          />
                        </b-button>
                        <b-button
                          v-b-tooltip.hover.v-danger
                          variant="outline-danger"
                          title="Cancel"
                          style="padding: 8px"
                          name="btn-cancel-edit"
                          @click="editField(data.item.id,'edit_login_id')"
                        >
                          <feather-icon
                            icon="XIcon"
                            size="13"
                            class="text-danger"
                          />
                        </b-button>
                      </template>
                    </b-input-group-append>
                  </b-input-group>
                  <small
                    v-if="errors[0]"
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </validation-provider>
              </validation-observer>
            </template>
            <template #cell(key)="data">
              <validation-observer :ref="`fieldKey_${data.item.id}`">
                <validation-provider
                  v-slot="{errors}"
                  rules="required|max:30"
                >
                  <b-input-group style="width: 330px; padding: 10px">
                    <b-form-input
                      v-model="data.item.new_key"
                      name="input-register-key"
                      placeholder="Write a new key"
                      :disabled="!data.item.edit_key"
                      :style="!data.item.edit_key ? (isDarkSkin ? 'background-color: #17202A !important;':'background-color: #EAEDED !important'):''"
                      :state="errors[0]?false:null"
                      :type="typeInputKey"
                    />
                    <b-input-group-append>
                      <template v-if="data.item.edit_key === false">
                        <b-button
                          v-b-tooltip.hover.v-warning
                          variant="outline-warning"
                          title="Edit"
                          style="padding: 8px"
                          @click="editField(data.item.id,'edit_key')"
                        >
                          <feather-icon
                            icon="Edit2Icon"
                            size="13"
                            class="text-warning"
                          />
                        </b-button>
                        <b-button
                          v-b-tooltip.hover.v-primary
                          variant="outline-light"
                          title="Tracking"
                          style="padding: 8px;border-left: 1px solid orange !important;"
                          @click="showTrackingCredentials(data.item.id, data.item.description,'key')"
                        >
                          <feather-icon
                            icon="ListIcon"
                            size="13"
                            class="text-primary"
                          />
                        </b-button>
                      </template>
                      <template v-else>
                        <b-button
                          v-b-tooltip.hover.v-success
                          variant="success"
                          title="Save"
                          style="padding: 8px"
                          @click="updateCredential(data.item.id)"
                        >
                          <feather-icon
                            icon="KeyIcon"
                            size="13"
                            class="text-white"
                          />
                        </b-button>
                        <b-button
                          v-b-tooltip.hover.v-danger
                          variant="outline-danger"
                          title="Cancel"
                          style="padding: 8px"
                          @click="editField(data.item.id,'edit_key')"
                        >
                          <feather-icon
                            icon="XIcon"
                            size="13"
                            class="text-danger"
                          />
                        </b-button>
                      </template>
                    </b-input-group-append>
                  </b-input-group>
                  <small
                    v-if="errors[0]"
                    class="text-danger"
                  >{{ errors[0] }}</small>
                </validation-provider>
              </validation-observer>
            </template>
            <template #cell(cards)="data">
              <div
                name="container-cards-merchants"
                class=" w-auto d-flex align-items-center justify-content-center"
                style="min-width: 200px"
              >
                <template v-if="JSON.parse(data.item.cards)">
                  <b-badge
                    v-for="(item, index) in JSON.parse(data.item.cards)"
                    :key="index"
                    v-b-tooltip="{title : item.name, variant: 'secondary', customClass: 'custom-tooltip'}"
                    class="rounded-sm badge-size"
                    style="margin: 3px;padding:10px"
                    :style="bgCards[item.name.replace(/\s/g, '').toLowerCase()]"
                  >

                    <img
                      :src="getSvgPath(item.id)"
                      :alt="item.name + ' Image'"
                      width="30"
                      height="50"
                    >
                  <!-- {{ item.name }} -->

                  </b-badge>
                </template>
              </div>
              <div class="d-flex justify-content-center align-items-center mt-1">
                <template v-if="JSON.parse(data.item.cards) && data.item.status==='Active'">
                  <feather-icon
                    v-b-tooltip.hover.v-warning
                    v-b-tooltip.hover.left
                    title="Edit cards"
                    icon="Edit3Icon"
                    size="16"
                    class="text-warning mr-2 hover-me"
                    @click="editTypeCardsMerchant(data.item)"
                  />
                </template>
                <template v-else>
                  <feather-icon
                    v-if="data.item.status==='Active'"
                    v-b-tooltip.hover.v-success
                    v-b-tooltip.hover.left
                    title="Add cards"
                    icon="PlusSquareIcon"
                    size="16"
                    class="text-success mr-2 hover-me"
                    @click="editTypeCardsMerchant(data.item)"
                  />
                </template>
                <feather-icon
                  v-b-tooltip.hover.v-primary
                  v-b-tooltip.hover.right
                  icon="ListIcon"
                  title="Tracking"
                  size="16"
                  class="text-primary hover-me"
                  @click="showTrackingCards(data.item.id, data.item.description)"
                />
              </div>
            </template>
            <template #cell(services)="data">
              <div
                class="d-flex align-items-center justify-content-center flex-wrap"
                style="width: 350px"
              >
                <b-badge
                  v-for="(item,index) in JSON.parse(data.item.services)"
                  :key="index"
                  class="rounded-sm"
                  style="margin: 3px;padding: 10px"
                >
                  {{ item.value }}
                </b-badge>
              </div>
              <div
                class="d-flex align-items-center justify-content-center"
                style="width: 350px; margin-top: 3px"
              >
                <template v-if="JSON.parse(data.item.services) && data.item.status==='Active'">
                  <feather-icon
                    v-b-tooltip.hover.top.v-warning
                    title="Edit Services"
                    icon="Edit3Icon"
                    size="17"
                    class="ml-1 mr-1 hover-me text-warning"
                    @click="editServicesMerchant(data.item)"
                  />
                </template>
                <template v-else>
                  <feather-icon
                    v-if="data.item.status==='Active'"
                    v-b-tooltip.hover.top
                    title="Add Services"
                    icon="PlusSquareIcon"
                    size="17"
                    class="ml-1 mr-1 text-success hover-me"
                    @click="editServicesMerchant(data.item)"
                  />
                </template>
                <feather-icon
                  v-b-tooltip.hover.v-primary
                  v-b-tooltip.hover.right
                  title="Tracking"
                  icon="ListIcon"
                  size="16"
                  class="text-primary hover-me"
                  @click="showTrackingPrograms(data.item.id, data.item.description)"
                />
              </div>
            </template>
            <template #cell(status)="data">
              <div @click="showStatusMerchant(data.item.id, data.item.status, data.item.services)" style="padding: 10px">
                <span :class="data.item.status === 'Active' ? 'text-primary' : 'text-warning'">
                  {{ data.item.status.toUpperCase() }}
                </span>
              </div>
            </template>
          </b-table>
        </template>
      </filter-slot>
    </b-card>
    <!--Modal types cards-->
    <modal-type-cards
      v-if="showModalTypeCards"
      :merchant="paramsMerchant"
      @closeModal="closeModalTypeCards"
      @successAction="successActionCards"
    />
    <!--Modal services-->
    <modal-services
      v-if="showModalServices"
      :merchant="paramsMerchant"
      @closeModal="closeModalServices"
      @successAction="successActionServices"
    />
    <!--Modal Tracking credentials-->
    <modal-tracking-credentials
      v-if="showModalTrackingCredentials"
      :merchant="paramsTrackingCredentials"
      @closeModal="showModalTrackingCredentials= false"
    />
    <!--Modal Tracking cards-->
    <modal-tracking-cards
      v-if="showModalTrackingCards"
      :merchant="paramsTrackingCards"
      @closeModal="showModalTrackingCards = false"
    />
    <!--Modal new merchant-->
    <modal-new-merchant
      v-if="showModalNewMerchant"
      @closeModal="showModalNewMerchant = false"
      @successAction="refreshTable()"
    />
    <!--Modal tracking programs-->
    <modal-tracking-programs
      v-if="showModalTrackingPrograms"
      :merchant="paramsTrackingPrograms"
      @closeModal="showModalTrackingPrograms=false"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ServicesMerchants from '@/views/management/views/settings/views/merchant/services/merchant.services';
import fields from '@/views/management/views/settings/views/merchant/data/fields.merchants';
import ModalTypeCards from '@/views/management/views/settings/views/merchant/modals/modalsTypeCards.vue';
import ModalTrackingCredentials from '@/views/management/views/settings/views/merchant/modals/modalTrackingCredentials.vue';
import ModalServices from '@/views/management/views/settings/views/merchant/modals/modalServices.vue';
import ModalTrackingCards from '@/views/management/views/settings/views/merchant/modals/modalTrackingCards.vue';
import ModalNewMerchant from '@/views/management/views/settings/views/merchant/modals/modalNewMerchant.vue';
import ModalTrackingPrograms from '@/views/management/views/settings/views/merchant/modals/modalTrackingPrograms.vue';

export default {
  components: {
    ModalTypeCards,
    ModalServices,
    ModalTrackingCredentials,
    ModalTrackingCards,
    ModalNewMerchant,
    ModalTrackingPrograms,
  },
  data() {
    return {
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search by Merchant name...',
        model: '',
      },
      searchInput: '',
      isBusy: false,
      fields,
      totalRows: 0,
      startPage: 0,
      toPage: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      items: [],
      bgCards: {
        visa: ' background-image: linear-gradient(to right, #00579f, #0064b6, #0071ce, #007ee6, #008cff);',
        mastercard: ' background-image: linear-gradient(to right, #eb001b, #f03e13, #f45d0c, #f6760b, #f88d13); ',
        americanexpress: '  background-image: linear-gradient(to right, #188bcd, #1596da, #11a2e6, #0aaef3, #00baff); ',
        discovery: ' background-image: linear-gradient(to right, #d06d0f, #db710c, #e77508, #f37804, #ff7c00); ',
      },
      paramsMerchant: {
        id: null,
        currentServices: [],
        currentTypeCards: [],
        description: null,
        merchantWithCardDiscovery: 0,
      },
      paramsTrackingCredentials: {
        merchantId: null,
        descriptionMerchant: null,
        field: null,
      },
      paramsTrackingCards: {
        merchantId: null,
        descriptionMerchant: null,
      },
      paramsTrackingPrograms: {
        merchantId: null,
        descriptionMerchant: null,
      },
      paramsStatusMerchant: {
        merchantId: null,
        status: null,
        services: null,
      },
      showModalTypeCards: false,
      showModalServices: false,
      showModalTrackingCredentials: false,
      showModalTrackingCards: false,
      showModalNewMerchant: false,
      showModalTrackingPrograms: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    typeInputKey() {
      if ([1, 41].includes(this.currentUser.user_id)) {
        return 'text';
      }
      return 'password';
    },
  },
  methods: {
    getSvgPath(id) {
      switch (id) {
        case 1:
          return require('@/views/management/views/settings/views/merchant/assets/visa.svg');
        case 2:
          return require('@/views/management/views/settings/views/merchant/assets/mastercard.svg');
        case 3:
          return require('@/views/management/views/settings/views/merchant/assets/americanexpress.svg');
        case 4:
          return require('@/views/management/views/settings/views/merchant/assets/discover.svg');
        default:
          return ''; // Return a default SVG path or handle unknown cases
      }
    },
    editField(merchantId, field) {
      const originalValues = {
        edit_login_id: 'login_id',
        edit_key: 'key',
      };
      this.items.forEach(item => {
        const nameField = `new_${originalValues[field]}`;
        if (item.id === merchantId) {
          item[field] = !item[field];
          if (!item[field]) {
            item[nameField] = item[originalValues[field]];
          } else if (field.toLowerCase() === 'edit_key') {
            item[nameField] = '';
          }
        } else {
          item[field] = false;
          item[nameField] = item[originalValues[field]];
        }
      });
    },
    async refreshTable() {
      this.$refs.refTableMerchants.refresh();
    },
    async providerMerchants() {
      try {
        this.isBusy = true;
        const params = {
          perPage: this.paginate.perPage,
          page: this.paginate.currentPage,
          search: this.filterPrincipal.model,
        };
        const { data } = await ServicesMerchants.getAllMerchants(params);
        this.items = data.data;
        this.totalRows = data.total || 0;
        this.startPage = data.from || 0;
        this.toPage = data.to || 0;
        this.paginate.currentPage = data.current_page;
        this.paginate.perPage = data.per_page;

        const registers = data.data.map(element => ({
          ...element,
          new_login_id: element.login_id,
          new_key: element.key,
          edit_login_id: false,
          edit_key: false,
        }));
        this.items = registers;
        this.isBusy = false;
        return this.items || [];
      } catch (error) {
        this.isBusy = false;
        this.showErrorSwal();
        console.log('error provider Merchants', error);
      }
      return [];
    },
    closeModalTypeCards() {
      this.showModalTypeCards = false;
    },
    closeModalServices() {
      this.showModalServices = false;
    },
    editTypeCardsMerchant({
      id, cards, description, merchants_with_card_discovery,
    }) {
      this.paramsMerchant.id = id;
      this.paramsMerchant.currentTypeCards = cards;
      this.paramsMerchant.description = description;
      this.paramsMerchant.merchantWithCardDiscovery = merchants_with_card_discovery;
      this.showModalTypeCards = true;
    },
    editServicesMerchant({
      id, services, description,
    }) {
      this.paramsMerchant.id = id;
      this.paramsMerchant.currentServices = services || '[]';
      this.paramsMerchant.description = description;
      this.showModalServices = true;
    },
    async updateCredential(merchantId) {
      const objMerchant = this.items.find(item => item.id === merchantId);
      try {
        /* -------VALID REQUIRED FIELDS------------*/
        const isValidLoginId = await this.$refs[`fieldLoginId_${merchantId}`].validate();
        const isValidKey = await this.$refs[`fieldKey_${merchantId}`].validate();
        if (!isValidLoginId || !isValidKey) {
          return;
        }
        /* -----------VALID 'new values (login id , key)' different to 'old values (login id , key)'--------- */
        if (objMerchant.new_login_id === objMerchant.login_id && objMerchant.new_key === objMerchant.key) {
          this.showInfoSwal('The \'new value\' must be different from \'old value\'');
          return;
        }
        /* -----------------CONFIRM UPDATE REGISTER--------------------- */
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) {
          return;
        }
        this.addPreloader();
        const params = {
          merchant_id: merchantId,
          login_id: objMerchant.new_login_id,
          key: objMerchant.new_key,
          user_id: this.currentUser.user_id,
        };
        const data = await ServicesMerchants.updateMerchantCredentials(params);

        if (data.status === 200) {
          this.showSuccessSwal();

          this.items.forEach(element => {
            if (element.id === merchantId) {
              element.login_id = element.new_login_id;
              element.key = element.new_key;
              element.edit_login_id = false;
              element.edit_key = false;
            }
          });
        }

        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        console.log('Error update merchant credentials', error);
        this.showErrorSwal();
      }
    },
    successActionCards() {
      this.showModalTypeCards = false;
      this.refreshTable();
    },
    successActionServices() {
      this.showModalServices = false;
      this.refreshTable();
    },
    showTrackingCredentials(merchantId, descriptionMerchant, field) {
      this.paramsTrackingCredentials.merchantId = merchantId;
      this.paramsTrackingCredentials.descriptionMerchant = descriptionMerchant;
      this.paramsTrackingCredentials.field = field;
      this.showModalTrackingCredentials = true;
    },
    showTrackingCards(merchantId, descriptionMerchant) {
      this.paramsTrackingCards.merchantId = merchantId;
      this.paramsTrackingCards.descriptionMerchant = descriptionMerchant;
      this.showModalTrackingCards = true;
    },
    showTrackingPrograms(merchantId, description) {
      this.paramsTrackingPrograms.merchantId = merchantId;
      this.paramsTrackingPrograms.descriptionMerchant = description;
      this.showModalTrackingPrograms = true;
    },
    showStatusMerchant(merchantId, status, services) {
      this.paramsStatusMerchant.merchantId = merchantId;
      this.paramsStatusMerchant.status = status;
      this.paramsStatusMerchant.services = services || '[]';
      this.showModalStatusMerchant = true;
    },
    closeModalStatusMerchant() {
      this.showModalStatusMerchant = false;
      this.refreshTable();
    },
  },
};
</script>
<style scoped>
.hover-me{
  transform: scale(1);
  transition: 0.3s ease-in-out;
}
.hover-me:hover{
  transform: scale(1.3);
}

.badge-size{
  font-size: 40px
}

b-table tbody tr td{
  padding: 0.5rem 0.75rem !important;
}
</style>
