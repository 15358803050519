export default [
  {
    key: 'cards',
    label: 'Cards',
  },
  {
    key: 'created_by',
    label: 'Created by',
  },
  {
    key: 'created_at',
    label: 'Created at',
  },
];
