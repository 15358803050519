export default [
  {
    key: 'description',
    label: 'Description',
    thClass: 'text-center',
    tdClass: 'p-1',
  },
  {
    key: 'login_id',
    label: 'Login id',
    thClass: 'text-center',
    tdClass: 'p-1',
  },
  {
    key: 'key',
    label: 'Key',
    thClass: 'text-center',
    tdClass: 'p-1',
  },
  {
    key: 'cards',
    label: 'Cards',
    thClass: 'text-center',
    tdClass: 'p-1 text-center',
  },
  {
    key: 'services',
    label: 'Services',
    thClass: 'text-center',
    tdClass: 'p-1',
  },
  {
    key: 'status',
    label: 'Status',
    thClass: 'text-center',
    tdClass: 'p-1',
  },
];
